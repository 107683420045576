.App {
  text-align: center;
}

.App-logo {
  height: 5vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-beat infinite 2s ease-in-out;
  }
}

.App-header {
  background-color: #0c0e11;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}


@keyframes App-logo-beat {
    0%
    {
      transform: scale( .75 );
    }
    20%
    {
      transform: scale( 0.85 );
    }
    40%
    {
      transform: scale( .75 );
    }
    60%
    {
      transform: scale( 0.85 );
    }
    80%
    {
      transform: scale( .75 );
    }
    100%
    {
      transform: scale( .75 );
    }
  }
